//* .\@\App.js
//* 
//* Script del componente principal ./@/App.vue. 
//*
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

import animacion        from '@/components/animacion.vue'

import menuTop          from '@/app_navbar.vue'

import store from './store'

import Vue from 'vue'
import VTitle from 'v-title';
Vue.use(VTitle);


export default {

    store,
    name: 'app',
    components: {
        animacion,

        menuTop,
    },
    data(){
        return{
          show: false,
        }
    },
}