//* ./@/app_menutablets.Vue
//*
//* Componente para la navegación y ruteo en dispositivos móviles
//*
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

<template id="menutablets">
    <div>
        <ul class="sidenav left" id="mobile-demo">

            <br>
            <!--//? 1. INICIO -->
            <router-link
                to="/"
                tag="li"
                exact>
                <a>
                    {{ $t("nav.inicio") }}
                </a>
            </router-link>
        </ul>
    </div>
</template>

<script>

    import {mapState, mapGetters } from 'vuex'

     export default {
        name: 'menutablets',
        computed: {
            ...mapState(['']),
            ...mapGetters(['']),
        },        
     }     
</script>

