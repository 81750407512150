//* .\@\app_navbarvue.js
//* 
//* Script del componente .\@\app_navbarvue.vue 
//*
//* Desarrollado por Lucía De León Madrid.
//* Agosto, 2021

import menutablets  from '@/app_menutablets.vue';

import {mapState, mapGetters } from 'vuex'

export default {

    name: 'navbar',
    data(){
        return {
            mostrarLogo: true,

            //? Variables para el TIMER
            countDownDate: new Date("oct 29 2018 00:00:00").getTime(),
            distance: 0,
            seconds: 0,  
        }
    },
    components: {
        menutablets,
    },
    mounted(){
        //? Luego activa el reloj para refrescar la vista si en caso los
        //? roles de estas opciones fueron cambiadas para el usuario en sesión.
        this.reloj();
    },
    methods:{
        reloj(){
            
            var vm = this
            var now = null;
      
            setInterval( () => {
      
                now= new Date().getTime();
      
                vm.distance = vm.countDownDate - now;
                vm.seconds = Math.floor((vm.distance % (1000 * 60)) / 1000);
      
                //* A cada 3 segundos se vuelve a cargar los MODULOS 
                if (vm.seconds % 10 === 0){
      
                    vm.ocultarLogo();
                }    
            }, 1000);
        },
        ocultarLogo(){
            this.mostrarLogo=false;
        },
      
    }
}