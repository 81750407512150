//* @\store\index.js
//* 
//* Script del vuex (almacén de datos).
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2021

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//? ................................................................................................
const mutations= {
  
  actualiceCategoria(state,laCategoria){

    state.st_categoriaSeleccionada= laCategoria;
  },

  actualiceIdioma(state,idiomaSeleccionado){

    console.log("recibiendo el idioma ? ",idiomaSeleccionado);

    state.st_idioma=idiomaSeleccionado;

    console.log("idioma setupeado ? ",state.st_idioma);

  }
}

export default new Vuex.Store({
  state:{
    st_categoriaSeleccionada: '* SIN CATEGORÍA *',
    formatos: '',
    st_familia: 'font-family:Quicksand',
    st_idioma: '',

    //? Para usar las funciones de Javascript que están en @/store/funciones.js
    st_lib: "",                 //* Este es necesario para acceder a las funciones de node.js
  },
  mutations,
})
