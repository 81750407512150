//* .\@\main.js
//* 
//* Script principal de todo proyecto Vue
//*
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

import 'materialize-css/dist/js/materialize.js'
import 'materialize-css/dist/css/materialize.css'
import M from 'materialize-css'

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  mounted(){
    
		document.addEventListener('DOMContentLoaded', function() {
			var elems = document.querySelectorAll('select');
			M.FormSelect.init(elems, {});
		});

    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {
      draggable: false,
    });

    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.fixed-action-btn');
      M.FloatingActionButton.init(elems, {
        toolbarEnabled: true,
      });            
    });

    document.addEventListener('DOMContentLoaded', function() {
        var elem = document.querySelectorAll('.tabs');
        M.Tabs.init(elem, {});		  
    });

    document.addEventListener('DOMContentLoaded', function() {
        var box = document.querySelectorAll('.modal');
        M.Modal.init(box, { dismissible: false });
    });

    document.addEventListener('DOMContentLoaded', function() {
      var slidecontent = document.querySelectorAll('.sidenav');
      M.Sidenav.init(slidecontent);
    });

    document.addEventListener('DOMContentLoaded', function() {
      var carouselContent = document.querySelectorAll('.carousel');
      M.Carousel.init(carouselContent,{indicators:true});
    });

    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.scrollspy');
      M.ScrollSpy.init(elems, {});
    });

    var elems = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(elems, {});    

    //? -------------------------------------------------------------------------
    store.state.st_lib = require("@/store/funciones.js");

    store.state.formatos= require("@/store/formatos.js");

    store.state.paginear= require("@/store/paginacion.js");
  },

  render: h => h(App)
}).$mount('#app')
