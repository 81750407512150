//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Septiembre, 2024

import Vue from 'vue'
import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(VueMarqueeSlider)

export default {
  name: 'Home',
  data () {
    return {

    } 
  },
  computed:{
    
  },  
  mounted(){

    M.AutoInit();
  },
} 
